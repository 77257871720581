<template>
  <div class="list">
    <div class="list-content">
      <div
        class="list-item"
        v-for="(modulo, index) in track.modules"
        :key="'modulo' + index + '_slide'"
      >
        <v-hover v-slot:default="{ hover }">
          <v-card elevation="9" class="niu-card-main" light>
            <v-row class="pa-0 ma-0" align="center" justify="center">
              <v-col class="pa-0 ma-0">
                <!-- open-delay="openDelay" -->
                <v-img
                  :aspect-ratio="17 / 9"
                  :src="
                    require('../../assets/' +
                      (modulo.moduloImgOriginal
                        ? modulo.moduloImgOriginal
                        : 'proximamente.png'))
                  "
                  lazy-src="../../assets/client-loader.gif"
                  class="align-center justify-center"
                >
                  <h1 style="text-align:center;">
                    <v-avatar
                      color="white"
                      :class="fondoModuloClass(modulo, hover)"
                    >
                      <v-icon @click="activeModulo(modulo)">mdi-play</v-icon>
                      <!-- {{ modulo.moduloNumber }} -->
                    </v-avatar>
                  </h1>
                </v-img>
              </v-col>
            </v-row>
            <v-row align="end" class="pa-2 ma-0" light>
              <v-col class="ma-0 pa-0 niu-text-carrusel" light>
                <div style="position:absolute;top: -25px;right: 20px;">
                  <v-avatar :class="colorFondoModuloClass(modulo)" size="42">
                    <span class="headline" style="color:white;">
                      {{ modulo.moduloNumber }}
                    </span>
                  </v-avatar>
                </div>
                <v-chip class="ma-2" :class="colorFondoModuloClass(modulo)">
                  Módulo {{ modulo.moduloNumber }}
                </v-chip>
                <span
                  v-if="!modulo.moduloHtml"
                  class="niu-carousel-texto-modulo"
                >
                  {{ modulo.title }}
                </span>
                <span
                  v-else
                  v-html="modulo.title"
                  class="niu-carousel-texto-modulo"
                ></span>
                <div style="position:relative">
                  <v-icon
                    style="position:absolute"
                    class="niu-text-black"
                    :class="iconModuloClass(modulo, hover)"
                  >
                    mdi-notebook
                  </v-icon>

                  <div style="position:relative; left:25px;">
                    <label class="niu-text">Pertenece a:</label><br />
                    <label class="niu-text " style="word-break:break-word;">
                      <strong>Track</strong>:
                      <span style="line-height: 18px;">{{
                        modulo.trackTitleOriginal
                      }}</span>
                    </label>
                  </div>
                </div>
                <p>
                  <v-icon
                    class="niu-text-black"
                    :class="iconModuloClass(modulo, hover)"
                  >
                    mdi-account
                  </v-icon>
                  <label class="niu-text"
                    ><strong>Especialista: </strong>
                    {{ modulo.moduloEspecialista }}</label
                  >
                </p>
              </v-col>
            </v-row>
            <div
              style="position: absolute;bottom: 15px;width: 90%;left: 5%;"
              align="center"
              justify="end"
            >
              <small
                class="niu-text-black niu-text-bold"
                :class="iconModuloClass(modulo, hover)"
                >Sesiones: {{ modulo.moduloNroSesionesOriginal }}</small
              >

              <small
                class="niu-text-black niu-text-bold ml-3"
                :class="iconModuloClass(modulo, hover)"
                >Tiempo:
                {{ modulo.moduloDuracionOriginal }}
                minutos</small
              >
            </div>
            <div
              class="niu-barra"
              :class="barraInferiorModuloCarrusel(modulo, hover)"
            ></div>
            <!-- <div class="niu-numero-modulo">
              {{ modulo.moduloNumber }}
            </div> -->
          </v-card>
        </v-hover>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
export default {
  props: {
    track: {
      type: Object
    }
  },
  computed: {
    ...mapState(['KnowledgeAreas2'])
  },
  methods: {
    ...mapMutations(['SET_INFOTRACK']),
    fondoModuloClass (modulo, hover) {
      return {
        'niu-background-gradient-emprendimientonegocio':
          modulo.areaconocimientoCode == 'emprendimientonegocio' && hover,
        'niu-background-gradient-liderazgo':
          modulo.areaconocimientoCode == 'liderazgo' && hover,
        'niu-background-gradient-comunicacion':
          modulo.areaconocimientoCode == 'comunicacion' && hover,
        'niu-background-gradient-desarrollofisico':
          modulo.areaconocimientoCode == 'desarrollofisico' && hover,
        'niu-background-gradient-desarrollopersonal':
          modulo.areaconocimientoCode == 'desarrollopersonal' && hover,
        'niu-background-gradient-conocimientotecnico':
          modulo.areaconocimientoCode == 'conocimientotecnico' && hover
      }
    },
    barraInferiorModuloCarrusel (modulo, hover) {
      return {
        'niu-background-solid-emprendimientonegocio':
          modulo.areaconocimientoCode == 'emprendimientonegocio' && hover,
        'niu-background-solid-liderazgo':
          modulo.areaconocimientoCode == 'liderazgo' && hover,
        'niu-background-solid-comunicacion':
          modulo.areaconocimientoCode == 'comunicacion' && hover,
        'niu-background-solid-desarrollofisico':
          modulo.areaconocimientoCode == 'desarrollofisico' && hover,
        'niu-background-solid-desarrollopersonal':
          modulo.areaconocimientoCode == 'desarrollopersonal' && hover,
        'niu-background-solid-conocimientotecnico':
          modulo.areaconocimientoCode == 'conocimientotecnico' && hover
      }
    },
    iconModuloClass (modulo, hover) {
      return {
        'niu-text-emprendimientonegocio':
          modulo.areaconocimientoCode == 'emprendimientonegocio' && hover,
        'niu-text-liderazgo':
          modulo.areaconocimientoCode == 'liderazgo' && hover,
        'niu-text-comunicacion':
          modulo.areaconocimientoCode == 'comunicacion' && hover,
        'niu-text-desarrollofisico':
          modulo.areaconocimientoCode == 'desarrollofisico' && hover,
        'niu-text-desarrollopersonal':
          modulo.areaconocimientoCode == 'desarrollopersonal' && hover,
        'niu-text-conocimientotecnico':
          modulo.areaconocimientoCode == 'conocimientotecnico' && hover
      }
    },
    colorFondoModuloClass (modulo) {
      return {
        'niu-background-solid-emprendimientonegocio':
          modulo.areaconocimientoCode == 'emprendimientonegocio',
        'niu-background-solid-liderazgo':
          modulo.areaconocimientoCode == 'liderazgo',
        'niu-background-solid-comunicacion':
          modulo.areaconocimientoCode == 'comunicacion',
        'niu-background-solid-desarrollofisico':
          modulo.areaconocimientoCode == 'desarrollofisico',
        'niu-background-solid-desarrollopersonal':
          modulo.areaconocimientoCode == 'desarrollopersonal',
        'niu-background-solid-conocimientotecnico':
          modulo.areaconocimientoCode == 'conocimientotecnico'
      }
    },
    activeModulo (moduloSeleccionado) {
      //   console.log(
      //     this.KnowledgeAreas2.find(
      //       ele => ele.descripcion == this.track.trackAreaconocimientoTitle
      //     )
      //   )

      let modulos = this.track.modules.map(ele => {
        return {
          agrupaModuloId: 0,
          areaconocimientoCode: ele.areaconocimientoCode,
          areaconocimientoColor1: ele.areaconocimientoColor1,
          areaconocimientoColor2: ele.areaconocimientoColor2,
          areaconocimientoId: ele.areaconocimientoId,
          areaconocimientoTitle: ele.areaconocimientoTitle,
          moduloAnio: 0,
          moduloBook: '',
          moduloCode: ele.moduloCode,
          moduloDescription: ele.moduloDescription,
          moduloDuracion: ele.moduloDuracionOriginal,
          moduloEspecialista: ele.moduloEspecialista,
          moduloHtml: parseInt(ele.moduloHtml),
          moduloId: parseInt(ele.moduloId),
          moduloImg: ele.moduloImg,
          moduloNroSesiones: ele.moduloNroSesionesOriginal,
          moduloNumber: ele.moduloNumber,
          moduloTitle: ele.title,
          moduloUserId: '',
          trackAreaconocimientoCode: this.KnowledgeAreas2.find(
            item => item.descripcion == this.track.trackAreaconocimientoTitle
          ).name,
          trackCode: this.track.trackCode,
          trackId: this.track.trackId,
          trackTitle: this.track.trackName
        }
      })
      let infoCarrusel = {
        trackTitle: this.track.trackName,
        trackCode: this.track.trackCode,
        trackId: this.track.trackId,
        trackAreaconocimientoCode: this.KnowledgeAreas2.find(
          ele => ele.descripcion == this.track.trackAreaconocimientoTitle
        ).name,
        trackProgreso: this.track.trackProgress,
        modulos
      }
      this.SET_INFOTRACK(infoCarrusel)
      //   console.log(
      //     this.track,
      //     moduloSeleccionado,
      //     this.$store.state.infoTrack,
      //     infoCarrusel
      //   )
      let miruta = `/miruta/${this.track.trackCode}#mod-${parseInt(
        moduloSeleccionado.moduloId
      )}`
      this.$router.push(miruta)
    }
  }
}
</script>
<style lang="scss" scoped>
$fondo: white;
$fondo_card: black;

.niu-light.niu-background {
  background-color: #f5f5f5 !important;
}
.niu-light.niu-text {
  color: black !important;
}
.niu-back-dialog-0 {
  background-color: #a52882 !important;
}
.niu-back-dialog-1 {
  background-color: #a50b7a !important;
}
.niu-back-dialog-2 {
  background-color: #590d53 !important;
}
.niu-iconos {
  font-size: 1.2rem;
  font-weight: normal;
  color: #717171;
}
.niu-label {
  display: inline-block;
  border-radius: 10px;
  line-height: 5px;
  padding: 10px;
  position: absolute;
  top: 0;
}

.niu-transparent-explora-modulos {
  background-color: $fondo !important;
}
.niu-numero-modulo {
  position: absolute;
  font-size: 16rem;
  top: 10%;
  left: 85%;
  text-shadow: -3px -3px #000;
  font-weight: bold;
}
.theme--dark.v-btn.v-btn--icon.niu-btn-disabled {
  color: transparent !important;
}
.v-responsive.v-image {
  border-radius: 16px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0;
}
.texto_modulo {
  transform: rotate(-45deg);
  display: block;
  position: absolute;
  top: 40px;
  left: 10px;
  font-size: 1.5rem;
  font-weight: bold;
  text-shadow: 1px 1px #000;
}
.box_vineta {
  width: 100%;
  height: 100%;
  border: 0px solid transparent;
  background: linear-gradient(
    -45deg,
    transparent 0,
    transparent 70%,
    rgba(0, 0, 0, 0.75) 0,
    rgba(0, 0, 0, 0.75) 30%
  );
}
.grid-container {
  display: grid;
  grid-template-columns: auto auto;
  margin-bottom: 0;
}
// $width: ;
// * {
//   position: relative;
// }

.niu-rounded-4 {
  border-radius: 4px;
}
.niu-rounded-tl-4 {
  border-top-left-radius: 4px;
}
.niu-rounded-tr-4 {
  border-top-right-radius: 4px;
}
.niu-rounded-br-4 {
  border-bottom-right-radius: 4px;
}
.niu-rounded-bl-4 {
  border-bottom-left-radius: 4px;
}
.niu-rounded-b-4 {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.niu-rounded-t-4 {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.v-sheet.niu-transparent {
  background-color: transparent;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0),
    0px 8px 10px 1px rgba(0, 0, 0, 0), 0px 3px 14px 2px rgba(0, 0, 0, 0) !important;
}
.niu-title-carousel {
  color: #fff;
  text-shadow: 1px 1px 1px #000;
}
.niu-position-relative {
  position: relative;
}
.niu-overflow-hidden {
  overflow: hidden;
}
.niu-text-carrusel {
  min-height: 100px;
}

$ancho: 330px;
$alto: 450px;
$altoSlid: 700px;
.niu-width-track {
  width: 96%;
  left: 2%;
  height: $altoSlid;
}
.niu-card-main {
  width: $ancho;
  height: 270px;
  margin-right: 20px;
  margin-left: 20px;
  margin-bottom: 15px;
  background-color: #f5f5f5 !important;
  border-radius: 16px !important;
  position: relative;
}

@media (max-width: 960px) and (min-width: 600px) {
  $fooPercent: 10%;

  .niu-card-main {
    width: $ancho * ((100% - $fooPercent) / 100%);
    height: $alto * ((100% - $fooPercent) / 100%);
    margin-right: 15px;
    margin-bottom: 15px * ((100% - $fooPercent) / 100%);
    // padding: 2px;
    // background-color: transparent;
    // border: 1px solid #000;
    border-radius: 16px * ((100% - $fooPercent) / 100%) !important;
  }
  .niu-width-track {
    width: 96%;
    left: 2%;
    height: $altoSlid * ((100% - $fooPercent) / 100%);
  }
}
@media (max-width: 600px) {
  $fooPercent: 3%;

  .niu-card-main {
    font-size: 10px;
    width: $ancho * ((100% - $fooPercent) / 100%);
    height: $alto * ((100% - $fooPercent) / 100%);
    margin-right: 20px;
    margin-bottom: 15px * ((100% - $fooPercent) / 100%);
    // padding: 2px;
    // background-color: transparent;
    // border: 1px solid #000;
    border-radius: 16px * ((100% - $fooPercent) / 100%) !important;
  }
  .niu-width-track {
    width: 96%;
    left: 2%;
    height: 700px;
  }
}
.niu-carousel-texto-modulo {
  text-align: left;
  /* bottom: 0; */
  /* height: 60px; */
  /* padding: 4px; */
  font-size: 1.25rem;
  font-weight: 700;
  word-break: break-word;
  text-transform: none;
  /* margin-bottom: 40px; */
  display: block;
  height: 65px;
}
.cicle-ball-area {
  width: 10px;
  height: 10px;
  background: white;
  display: inline-block;
  border-radius: 50%;
  margin: 0 4px 0 0;
}
.v-icon.notranslate.mdi.mdi-chevron-left,
.v-icon.notranslate.mdi.mdi-chevron-right {
  font-size: 70px !important;
}
/* .niu-width-track {
  max-width: 100%;
} */
// @media (min-width: 300px) and (max-width: 320px) {
//   .niu-card-main {
//     height: 170px;
//     width: 200px;
//   }
//   .niu-width-track {
//     width: 280px;
//   }
// }
// @media (min-width: 320px) and (max-width: 340px) {
//   .niu-card-main {
//     height: 170px;
//     width: 200px;
//   }
//   .niu-width-track {
//     width: 300px;
//   }
// }
// @media (min-width: 340px) and (max-width: 380px) {
//   .niu-card-main {
//     height: 170px;
//     width: 200px;
//   }
//   .niu-width-track {
//     width: 320px;
//   }
// }
// @media (min-width: 380px) and (max-width: 400px) {
//   .niu-card-main {
//     height: 170px;
//     width: 200px;
//   }
//   .niu-width-track {
//     width: 360px;
//   }
// }
// @media (min-width: 400px) and (max-width: 420px) {
//   .niu-card-main {
//     height: 170px;
//     width: 200px;
//   }
//   .niu-width-track {
//     width: 320px;
//   }
// }
// @media (min-width: 420px) and (max-width: 440px) {
//   .niu-card-main {
//     height: 170px;
//     width: 200px;
//   }
//   .niu-width-track {
//     width: 340px;
//   }
// }
// @media (min-width: 440px) and (max-width: 460px) {
//   .niu-card-main {
//     height: 170px;
//     width: 200px;
//   }
//   .niu-width-track {
//     width: 365px;
//   }
// }
// @media (min-width: 460px) and (max-width: 480px) {
//   .niu-card-main {
//     height: 170px;
//     width: 200px;
//   }
//   .niu-width-track {
//     width: 385px;
//   }
// }
// @media (min-width: 480px) and (max-width: 500px) {
//   .niu-card-main {
//     height: 170px;
//     width: 200px;
//   }
//   .niu-width-track {
//     width: 405px;
//   }
// }
// @media (min-width: 500px) and (max-width: 520px) {
//   .niu-card-main {
//     height: 175px;
//     width: 225px;
//   }
//   .niu-width-track {
//     width: 465px;
//     height: 240px;
//   }
//   .niu-text-carrusel {
//     height: 200px;
//   }
// }
// @media (min-width: 520px) and (max-width: 540px) {
//   .niu-card-main {
//     height: 130px;
//     width: 158px;
//   }
//   .niu-width-track {
//     width: 495px;
//     height: 250px;
//   }
//   .niu-text-carrusel {
//     height: 200px;
//   }
// }
// @media (min-width: 540px) and (max-width: 560px) {
//   .niu-card-main {
//     height: 199px;
//     width: 224px;
//   }
//   .niu-width-track {
//     width: 465px;
//   }
// }
// @media (min-width: 560px) and (max-width: 580px) {
//   .niu-card-main {
//     height: 199px;
//     width: 234px;
//   }
//   .niu-width-track {
//     width: 485px;
//   }
// }
// @media (min-width: 580px) and (max-width: 600px) {
//   .niu-card-main {
//     height: 199px;
//     width: 244px;
//   }
//   .niu-width-track {
//     width: 505px;
//   }
// }
// @media (min-width: 600px) and (max-width: 620px) {
//   .niu-card-main {
//     height: 199px;
//     width: 255px;
//   }
//   .niu-width-track {
//     width: 525px;
//   }
// }
// @media (min-width: 620px) and (max-width: 640px) {
//   .niu-card-main {
//     height: 191px;
//     width: 261px;
//   }
//   .niu-width-track {
//     width: 545px;
//   }
// }
// @media (min-width: 640px) and (max-width: 660px) {
//   .niu-card-main {
//     height: 214px;
//     width: 273px;
//   }
//   .niu-width-track {
//     width: 565px;
//   }
// }
// @media (min-width: 660px) and (max-width: 680px) {
//   .niu-card-main {
//     height: 282px;
//     width: 188px;
//   }
//   .niu-width-track {
//     width: 585px;
//   }
//   .v-slide-group {
//     height: 300px;
//   }
// }
// @media (min-width: 680px) and (max-width: 700px) {
//   .niu-card-main {
//     height: 150px;
//     width: 195px;
//   }
//   .niu-width-track {
//     width: 605px;
//   }
// }
// @media (min-width: 700px) and (max-width: 720px) {
//   .niu-card-main {
//     height: 150px;
//     width: 195px;
//   }
//   .niu-width-track {
//     width: 625;
//   }
// }
// @media (min-width: 720px) and (max-width: 740px) {
//   .niu-card-main {
//     height: 157px;
//     width: 200px;
//   }
//   .niu-width-track {
//     width: 645;
//   }
// }
// @media (min-width: 740px) and (max-width: 760px) {
//   .niu-card-main {
//     height: 165px;
//     width: 212px;
//   }
//   .niu-width-track {
//     width: 665px;
//   }
// }
// @media (min-width: 760px) and (max-width: 780px) {
//   .niu-card-main {
//     height: 171px;
//     width: 219px;
//   }
//   .niu-width-track {
//     width: 685px;
//   }
// }
// @media (min-width: 780px) and (max-width: 800px) {
//   .niu-card-main {
//     height: 179px;
//     width: 225px;
//   }
//   .niu-width-track {
//     width: 705px;
//   }
// }
// @media (min-width: 800px) and (max-width: 820px) {
//   .niu-card-main {
//     height: 182px;
//     width: 232px;
//   }
//   .niu-width-track {
//     width: 725px;
//   }
// }
// @media (min-width: 820px) and (max-width: 840px) {
//   .niu-card-main {
//     height: 187px;
//     width: 239px;
//   }
//   .niu-width-track {
//     width: 745px;
//   }
// }
// @media (min-width: 840px) and (max-width: 860px) {
//   .niu-card-main {
//     height: 143px;
//     width: 183px;
//   }
//   .niu-width-track {
//     width: 765px;
//   }
// }
// @media (min-width: 860px) and (max-width: 880px) {
//   .niu-card-main {
//     height: 202px;
//     width: 252px;
//   }
//   .niu-width-track {
//     width: 785px;
//   }
// }
// @media (min-width: 880px) and (max-width: 900px) {
//   .niu-card-main {
//     height: 150px;
//     width: 195px;
//   }
//   .niu-width-track {
//     width: 805px;
//   }
// }
// @media (min-width: 900px) and (max-width: 920px) {
//   .niu-card-main {
//     height: 206px;
//     width: 266px;
//   }
//   .niu-width-track {
//     width: 825px;
//   }
// }
// @media (min-width: 920px) and (max-width: 940px) {
//   .niu-card-main {
//     height: 156px;
//     width: 202px;
//   }
//   .niu-width-track {
//     width: 845px;
//   }
// }
// @media (min-width: 940px) and (max-width: 960px) {
//   .niu-card-main {
//     height: 167px;
//     width: 209px;
//   }
//   .niu-width-track {
//     width: 865px;
//   }
// }
// @media (min-width: 960px) and (max-width: 980px) {
//   .niu-card-main {
//     height: 169px;
//     width: 213px;
//   }
//   .niu-width-track {
//     width: 885px;
//   }
// }
// @media (min-width: 980px) and (max-width: 1000px) {
//   .niu-card-main {
//     height: 173px;
//     width: 217px;
//   }
//   .niu-width-track {
//     width: 905px;
//   }
// }
// @media (min-width: 1000px) and (max-width: 1020px) {
//   .niu-card-main {
//     height: 170px;
//     width: 218px;
//   }
//   .niu-width-track {
//     width: 910px;
//   }
// }
// @media (min-width: 1020px) and (max-width: 1040px) {
//   .niu-card-main {
//     height: 178px;
//     width: 227px;
//   }
//   .niu-width-track {
//     width: 945px;
//   }
// }
// @media (min-width: 1040px) and (max-width: 1060px) {
//   .niu-card-main {
//     height: 181px;
//     width: 229px;
//   }
//   .niu-width-track {
//     width: 954px;
//   }
// }
// @media (min-width: 1060px) and (max-width: 1080px) {
//   .niu-card-main {
//     height: 182px;
//     width: 234px;
//   }
//   .niu-width-track {
//     width: 974px;
//   }
// }
// @media (min-width: 1080px) and (max-width: 1100px) {
//   .niu-card-main {
//     height: 183px;
//     width: 237px;
//   }
//   .niu-width-track {
//     width: 985px;
//   }
// }
// @media (min-width: 1100px) and (max-width: 1120px) {
//   .niu-card-main {
//     height: 187px;
//     width: 241px;
//   }
//   .niu-width-track {
//     width: 1000px;
//   }
// }
// @media (min-width: 1120px) and (max-width: 1140px) {
//   .niu-card-main {
//     height: 190px;
//     width: 246px;
//   }
//   .niu-width-track {
//     width: 1020px;
//   }
// }
// @media (min-width: 1140px) and (max-width: 1160px) {
//   .niu-card-main {
//     height: 194px;
//     width: 251px;
//   }
//   .niu-width-track {
//     width: 1040px;
//   }
// }
// @media (min-width: 1160px) and (max-width: 1180px) {
//   .niu-card-main {
//     height: 159px;
//     width: 203px;
//   }
//   .niu-width-track {
//     width: 1060px;
//   }
// }
// @media (min-width: 1180px) and (max-width: 1200px) {
//   .niu-card-main {
//     height: 205px;
//     width: 262px;
//   }
//   .niu-width-track {
//     width: 1080px;
//   }
// }
// @media (min-width: 1200px) and (max-width: 1220px) {
//   .niu-card-main {
//     height: 209px;
//     width: 267px;
//   }
//   .niu-width-track {
//     width: 1100px;
//   }
// }
// @media (min-width: 1220px) and (max-width: 1240px) {
//   .niu-card-main {
//     height: 210px;
//     width: 271px;
//   }
//   .niu-width-track {
//     width: 1120px;
//   }
// }
// @media (min-width: 1240px) and (max-width: 1260px) {
//   .niu-card-main {
//     height: 176px;
//     width: 219px;
//   }
//   .niu-width-track {
//     width: 1140px;
//   }
// }
// @media (min-width: 1260px) and (max-width: 1280px) {
//   .niu-card-main {
//     height: 176px;
//     width: 219px;
//   }
//   .niu-width-track {
//     width: 1140px;
//   }
// }
// @media (min-width: 1280px) and (max-width: 1300px) {
//   .niu-card-main {
//     height: 173px;
//     width: 223px;
//   }
//   .niu-width-track {
//     width: 1160px;
//   }
// }
// @media (min-width: 1300px) and (max-width: 1320px) {
//   .niu-card-main {
//     height: 177px;
//     width: 227px;
//   }
//   .niu-width-track {
//     width: 1180px;
//   }
// }
// @media (min-width: 1320px) and (max-width: 1340px) {
//   .niu-card-main {
//     height: 180px;
//     width: 231px;
//   }
//   .niu-width-track {
//     width: 1200px;
//   }
// }
// @media (min-width: 1340px) and (max-width: 1360px) {
//   .niu-card-main {
//     height: 184px;
//     width: 236px;
//   }
//   .niu-width-track {
//     width: 1220px;
//   }
// }
// @media (min-width: 1360px) and (max-width: 1380px) {
//   .niu-card-main {
//     height: 188px;
//     width: 241px;
//   }
//   .niu-width-track {
//     width: 1240px;
//   }
// }
// @media (min-width: 1380px) and (max-width: 1400px) {
//   .niu-card-main {
//     height: 188px;
//     width: 241px;
//   }
//   .niu-width-track {
//     width: 1240px;
//   }
// }
// @media (min-width: 1400px) and (max-width: 1420px) {
//   .niu-card-main {
//     height: 189px;
//     width: 243px;
//   }
//   .niu-width-track {
//     width: 1260px;
//   }
// }
// @media (min-width: 1420px) and (max-width: 1440px) {
//   .niu-card-main {
//     height: 192px;
//     width: 247px;
//   }
//   .niu-width-track {
//     width: 1280px;
//   }
// }
// @media (min-width: 1440px) and (max-width: 1460px) {
//   .niu-card-main {
//     height: 195px;
//     width: 251px;
//   }
//   .niu-width-track {
//     width: 1300px;
//   }
// }
// @media (min-width: 1460px) and (max-width: 1480px) {
//   .niu-card-main {
//     height: 197px;
//     width: 255px;
//   }
//   .niu-width-track {
//     width: 1320px;
//   }
// }
// @media (min-width: 1480px) and (max-width: 1500px) {
//   .niu-card-main {
//     height: 201px;
//     width: 259px;
//   }
//   .niu-width-track {
//     width: 1340px;
//   }
// }
// @media (min-width: 1500px) and (max-width: 1520px) {
//   .niu-card-main {
//     height: 205px;
//     width: 263px;
//   }
//   .niu-width-track {
//     width: 1360px;
//   }
// }
// @media (min-width: 1520px) and (max-width: 1540px) {
//   .niu-card-main {
//     height: 230px;
//     width: 290px;
//   }
//   .niu-width-track {
//     width: 1490px;
//     height: 300px;
//   }
// }
// @media (min-width: 1540px) and (max-width: 1560px) {
//   .niu-card-main {
//     height: 209px;
//     width: 271px;
//   }
//   .niu-width-track {
//     width: 1400px;
//   }
// }
// @media (min-width: 1560px) and (max-width: 1580px) {
//   .niu-card-main {
//     height: 209px;
//     width: 271px;
//   }
//   .niu-width-track {
//     width: 1420px;
//   }
// }
// @media (min-width: 1580px) and (max-width: 1600px) {
//   .niu-card-main {
//     height: 209px;
//     width: 271px;
//   }
//   .niu-width-track {
//     width: 1440px;
//   }
// }
// @media (min-width: 1600px) and (max-width: 1620px) {
//   .niu-card-main {
//     height: 209px;
//     width: 271px;
//   }
//   .niu-width-track {
//     width: 1460px;
//   }
// }
// @media (min-width: 1620px) and (max-width: 1640px) {
//   .niu-card-main {
//     height: 209px;
//     width: 271px;
//   }
//   .niu-width-track {
//     width: 1480px;
//   }
// }
// @media (min-width: 1640px) and (max-width: 1660px) {
//   .niu-card-main {
//     height: 209px;
//     width: 271px;
//   }
//   .niu-width-track {
//     width: 1500px;
//   }
// }
// @media (min-width: 1660px) and (max-width: 1680px) {
//   .niu-card-main {
//     height: 209px;
//     width: 271px;
//   }
//   .niu-width-track {
//     width: 1520px;
//   }
// }
// @media (min-width: 1680px) and (max-width: 1700px) {
//   .niu-card-main {
//     height: 209px;
//     width: 271px;
//   }
//   .niu-width-track {
//     width: 1540px;
//   }
// }
// @media (min-width: 1700px) and (max-width: 1720px) {
//   .niu-card-main {
//     height: 209px;
//     width: 271px;
//   }
//   .niu-width-track {
//     width: 1560px;
//   }
// }
// @media (min-width: 1720px) and (max-width: 1740px) {
//   .niu-card-main {
//     height: 209px;
//     width: 271px;
//   }
//   .niu-width-track {
//     width: 1580px;
//   }
// }
// @media (min-width: 1740px) and (max-width: 1760px) {
//   .niu-card-main {
//     height: 209px;
//     width: 271px;
//   }
//   .niu-width-track {
//     width: 1600px;
//   }
// }
// @media (min-width: 1760px) and (max-width: 1780px) {
//   .niu-card-main {
//     height: 209px;
//     width: 271px;
//   }
//   .niu-width-track {
//     width: 1620px;
//   }
// }
// @media (min-width: 1780px) and (max-width: 1800px) {
//   .niu-card-main {
//     height: 209px;
//     width: 271px;
//   }
//   .niu-width-track {
//     width: 1640px;
//   }
// }
// @media (min-width: 1800px) and (max-width: 1820px) {
//   .niu-card-main {
//     height: 209px;
//     width: 271px;
//   }
//   .niu-width-track {
//     width: 1660px;
//   }
// }
// @media (min-width: 1820px) and (max-width: 1840px) {
//   .niu-card-main {
//     height: 209px;
//     width: 271px;
//   }
//   .niu-width-track {
//     width: 1680px;
//   }
// }
// @media (min-width: 1840px) and (max-width: 1860px) {
//   .niu-card-main {
//     height: 209px;
//     width: 271px;
//   }
//   .niu-width-track {
//     width: 1700px;
//   }
// }
// @media (min-width: 1860px) and (max-width: 1880px) {
//   .niu-card-main {
//     height: 209px;
//     width: 271px;
//   }
//   .niu-width-track {
//     width: 1720px;
//   }
// }
// @media (min-width: 1880px) and (max-width: 1900px) {
//   .niu-card-main {
//     height: 209px;
//     width: 271px;
//   }
//   .niu-width-track {
//     width: 1740px;
//   }
// }
// @media (min-width: 1900px) and (max-width: 1920px) {
//   .niu-card-main {
//     height: 209px;
//     width: 271px;
//   }
//   .niu-width-track {
//     width: 1760px;
//   }
// }
// .theme--dark.v-sheet.niu-card-main {
//   background-color: #333333;
//   border-color: #333333;
// }
.niu-hover {
  border-radius: 8px;
  border: 3px solid #444;
  border-radius: 8px;
}
.niu-border-emprendimientonegocio-dark {
  border-color: #ffae04 !important;
}
.niu-border-emprendimientonegocio-light {
  border-color: #ffe800 !important;
}
.niu-border-liderazgo-dark {
  border-color: #f64600 !important;
}
.niu-border-liderazgo-light {
  border-color: #f39d00 !important;
}
.niu-border-desarrollofisico-dark {
  border-color: #770005 !important;
}
.niu-border-desarrollofisico-light {
  border-color: #f40000 !important;
}
.niu-border-desarrollopersonal-dark {
  border-color: #2d6b00 !important;
}
.niu-border-desarrollopersonal-light {
  border-color: #2ae82d !important;
}
.niu-border-conocimientotecnico-dark {
  border-color: #11b29c !important;
}
.niu-border-conocimientotecnico-light {
  border-color: #3effe8 !important;
}
.niu-border-comunicacion-dark {
  border-color: #354CF9 !important;
}
.niu-border-comunicacion-light {
  border-color: #0540f2 !important;
}

.niu-barra {
  width: 70%;
  height: 5px;
  background-color: black;
  position: absolute;
  bottom: 0;
  z-index: 20;
  border-radius: 2px;
  left: 15%;
}
.v-icon.notranslate.mdi.mdi-chevron-right,
.v-icon.notranslate.mdi.mdi-chevron-left {
  font-size: 70px;
  border: 1px solid;
  border-radius: 50%;
}
.niu-background-gradient-emprendimientonegocio {
  background: rgb(255, 174, 4);
  background: linear-gradient(
    45deg,
    rgba(255, 174, 4, 1) 0%,
    #FFB92E 100%
  );
}
.niu-background-gradient-liderazgo {
  background: rgb(246, 70, 0);
  background: linear-gradient(
    45deg,
    rgba(246, 70, 0, 1) 0%,
    #C10000 100%
  );
}
.niu-background-gradient-desarrollofisico {
  background: #FC3A72;
  background: linear-gradient(
    45deg,
    rgba(119, 0, 5, 1) 0%,
    rgba(244, 0, 0, 1) 100%
  );
}
.niu-background-gradient-desarrollopersonal {
  background: #8AC445;
  background: linear-gradient(
    45deg,
    rgba(45, 107, 0, 1) 0%,
    rgba(42, 232, 45, 1) 100%
  );
}
.niu-background-gradient-conocimientotecnico {
  background: rgb(17, 178, 156);
  background: linear-gradient(
    45deg,
    #40CCB1 0%,
    rgba(62, 255, 232, 1) 100%
  );
}
.niu-background-gradient-comunicacion {
  background: rgb(3, 0, 42);
  background: linear-gradient(45deg, #354CF9 0%, #0540f2 100%);
}

.niu-background-gradient-emprendimientonegocio {
  background: #FFB92E;
  background: linear-gradient(
    45deg,
    #FFB92E 0%,
    rgba(255, 174, 4, 1) 100%
  );
}
.niu-background-gradient-liderazgo {
  background: #C10000;
  background: linear-gradient(
    45deg,
    #C10000 0%,
    rgba(246, 70, 0, 1) 100%
  );
}
.niu-background-gradient-desarrollofisico {
  background: rgba(244, 0, 0, 1);
  background: linear-gradient(
    45deg,
    rgba(244, 0, 0, 1) 0%,
    rgba(119, 0, 5, 1) 100%
  );
}
.niu-background-gradient-desarrollopersonal {
  background: rgba(42, 232, 45, 1);
  background: linear-gradient(
    45deg,
    rgba(42, 232, 45, 1) 0%,
    rgba(45, 107, 0, 1) 100%
  );
}
.niu-background-gradient-conocimientotecnico {
  background: rgba(62, 255, 232, 1);
  background: linear-gradient(
    45deg,
    rgba(62, 255, 232, 1) 0%,
    #40CCB1 100%
  );
}
.niu-background-gradient-comunicacion {
  background: rgb(3, 0, 42);
  background: linear-gradient(45deg, #354CF9 0%, #0540f2 100%);
}
.v-icon.notranslate.mdi.mdi-chevron-right,
.v-icon.notranslate.mdi.mdi-chevron-left {
  font-size: 70px !important;
  color: #ababab !important;
}

$emprendimientonegocio: #FFB92E;
$liderazgo: #C10000;
$desarrollofisico: #FC3A72;
$desarrollopersonal: #8AC445;
$conocimientotecnico: #40CCB1;
$comunicacion: #354CF9;

.niu-text {
  font-size: 0.9rem;
}

.niu-text-bold {
  font-weight: bold;
  font-size: 0.9rem;
}

.niu-text-black {
  color: rgba(0, 0, 0, 1) !important;
}
.niu-text-emprendimientonegocio {
  color: $emprendimientonegocio !important;
}
.niu-text-liderazgo {
  color: $liderazgo !important;
}
.niu-text-desarrollofisico {
  color: $desarrollofisico !important;
}
.niu-text-desarrollopersonal {
  color: $desarrollopersonal !important;
}
.niu-text-conocimientotecnico {
  color: $conocimientotecnico !important;
}
.niu-text-comunicacion {
  color: $comunicacion !important;
}

.v-chip.niu-background-solid-emprendimientonegocio,
.niu-background-solid-emprendimientonegocio {
  background: $emprendimientonegocio !important;
}
.v-chip.niu-background-solid-liderazgo,
.niu-background-solid-liderazgo {
  background: $liderazgo !important;
}
.v-chip.niu-background-solid-desarrollofisico,
.niu-background-solid-desarrollofisico {
  background: $desarrollofisico !important;
}
.v-chip.niu-background-solid-desarrollopersonal,
.niu-background-solid-desarrollopersonal {
  background: $desarrollopersonal !important;
}
.v-chip.niu-background-solid-conocimientotecnico,
.niu-background-solid-conocimientotecnico {
  background: $conocimientotecnico !important;
}
.v-chip.niu-background-solid-comunicacion,
.niu-background-solid-comunicacion {
  background: $comunicacion !important;
}
.v-chip.niu-background-solid-emprendimientonegocio {
  color: white !important;
}
.v-chip.niu-background-solid-liderazgo {
  color: white !important;
}
.v-chip.niu-background-solid-desarrollofisico {
  color: white !important;
}
.v-chip.niu-background-solid-desarrollopersonal {
  color: white !important;
}
.v-chip.niu-background-solid-conocimientotecnico {
  color: white !important;
}
.v-chip.niu-background-solid-comunicacion {
  color: white !important;
}
.v-application p {
  margin-bottom: 4px !important;
}

.list-content {
  /* border: 1px solid red; */
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
}
.list-item {
  display: inline-block;
  margin-left: 10px;
}
.list-item:first-child {
  margin: 0;
}

.list {
  /* border: 1px solid green; */
  padding: 0 1em;
}

main {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.niu-width-track {
  height: 520px;
}
@media (max-width: 600px) and (min-width: 400px) {
  .text-track-title {
    font-size: 18px;
    width: 75%;
    left: 20%;
  }
}

@media (max-width: 400px) and (min-width: 300px) {
  .text-track-title {
    font-size: 18px;
    width: 75%;
    left: 20%;
  }
}
</style>
