<template>
  <v-row class="mt-16">
    <v-col cols="12" md="2" xl="2" sm="4" style="background-color: #462054">
      <v-row>
        <v-col>
          <h1>Hola</h1>
        </v-col>
      </v-row>
      <v-row :class="activeMenu('miscursos')">
        <v-col>
          <label @click="activeMiAprendizaje('miscursos')">
            <v-icon right>
              mdi-checkbox-marked-circle
            </v-icon>
            Mis Cursos
          </label>
        </v-col>
      </v-row>
      <v-row :class="activeMenu('misrutas')">
        <v-col>
          <label @click="activeMiAprendizaje('misrutas')">
            <v-icon right>
              mdi-checkbox-marked-circle
            </v-icon>
            Mis Rutas
          </label>
        </v-col>
      </v-row>
      <!-- <v-row :class="activeMenu('verdespues')">
        <v-col>
          <label @click="activeMiAprendizaje('verdespues')">
            <v-icon dark right>
              mdi-checkbox-marked-circle
            </v-icon>
            Guardados
          </label>
        </v-col>
      </v-row> -->
    </v-col>
    <v-col cols="10" md="10" xl="10" sm="8">
      <v-row class="pa-8" v-if="miaprendizaje.miscursos">
        <v-col
          cols="12"
          class="mt-0 mb-0 pt-0 pb-0"
          v-for="(track, index) in progressTracks.filter(
            ele => ele.est_track_origin_id != 0
          )"
          :key="index"
        >
          <MiAprendizajeCurso :track="track" :color="track.color"></MiAprendizajeCurso>
        </v-col>
        <v-col v-if="progressTracks.length == 0">
          <v-sheet light class="transparent">
            <h1>Aún no has creado tu ruta de aprendizaje personalizada</h1>
            <h1>Créala en la sección de "Áreas de conocimiento"</h1>
            <v-btn class="primary" to="/universo">
              o Presionando Aquí
            </v-btn>
          </v-sheet>
        </v-col>
      </v-row>
      <v-row class="pa-8" v-if="miaprendizaje.misrutas">
        <v-col
          cols="12"
          class="mt-0 mb-0 pt-0 pb-0"
          v-for="(track, index) in progressTracks.filter(
            ele => ele.est_track_origin_id == 0
          )"
          :key="index"
        >
          <RouteItem :track="track" :color="track.color"></RouteItem>
        </v-col>
        <v-col v-if="progressTracks.length == 0">
          <v-sheet light class="transparent">
            <h1>Aún no has creado tu ruta de aprendizaje personalizada</h1>
            <h1>Créala en la sección de "Áreas de conocimiento"</h1>
            <v-btn class="primary" to="/universo">
              o Presionando Aquí
            </v-btn>
          </v-sheet>
        </v-col>
      </v-row>

      <v-row class="pa-8" v-if="miaprendizaje.verdespues"> </v-row>
    </v-col>
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import { mapMutations } from 'vuex'
import RouteItem from '../components/progreso/RouteItem.vue'
import MiAprendizajeCurso from '../components/progreso/miaprendizaje_curso.vue'
export default {
  computed: {
    ...mapState(['progressTracks'])
  },
  components: {
    RouteItem,
    MiAprendizajeCurso
  },
  methods: {
    ...mapMutations([
      'SET_AREAS',
      'SET_TRACK',
      'SET_MASTERTRACK',
      'CLEAR_learningPathData',
      'SET_PROGRESSTRACKS'
    ]),
    activeMenu (params) {
      return {
        'niu-button-active-miaprendizaje': this.miaprendizaje[params]
      }
    },
    activeMiAprendizaje (params) {
      // eslint-disable-next-line no-console
      console.log(this.miaprendizaje)
      for (let x in this.miaprendizaje) {
        if (x == params) {
          this.miaprendizaje[x] = true
        } else {
          this.miaprendizaje[x] = false
        }
      }
      // eslint-disable-next-line no-console
      console.log(this.miaprendizaje)
    }
  },
  async created () {
    let areaconocimiento = (
      await this.$http.get('/areasconocimientos/universo')
    ).data
    this.SET_AREAS(areaconocimiento)
    this.areaconocimientoUniverso = areaconocimiento
    this.modulos = (await this.$http.get('/universos/track')).data

    this.areaconocimiento = (
      await this.$http(`/AreasConocimientos/areasExplora`)
    ).data

    this.SET_PROGRESSTRACKS((await this.$http.get('progresos/track')).data)
    // eslint-disable-next-line no-console
    console.log(this.progressTracks)
  },
  mounted () {},
  data () {
    return {
      // progressTracks: [],
      miaprendizaje: {
        miscursos: true,
        misrutas: false,
        verdespues: false
      },
      areaconocimiento: null,
      modulos: [],
      areaconocimientoUniverso: [],
      filtro: [],
      filtrosChip: [],
      e6: 1,
      selectedItem: 1,
      items: [
        { text: 'Real-Time', icon: 'mdi-clock' },
        { text: 'Audience', icon: 'mdi-account' },
        { text: 'Conversions', icon: 'mdi-flag' }
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
.niu-text-niufleex2 {
  color: #4d1552;
  font-size: 2em;
  font-weight: bold;
}
.niu-text-niufleex3 {
  color: #4d1552;
  font-size: 1em;
  font-weight: bold;
}
.niu-text-niufleex-area {
  color: #959595;
  font-size: 1.5rem;
  margin-top: -2rem;
}
.niu-button-active-miaprendizaje {
  background-color: #fff;
  color: #000;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}
</style>
