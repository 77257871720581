var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-slide-group',{staticClass:"pa-0 ma-0 elevation-0",attrs:{"active-class":"success","show-arrows":"","light":""}},_vm._l((_vm.track.modules),function(modulo,index){return _c('v-slide-item',{key:'modulo' + index + '_slide',staticClass:"pa-0 ma-0 rounded-lg",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"niu-card-main-miaprendizaje rounded-lg  pa-0 ma-0  elevation-0",style:(("background-color:" + (_vm.$vuetify.theme.defaults.dark.secondary)))},[_c('v-row',{staticClass:"pa-0 ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"pa-0 ma-0"},[_c('v-img',{staticClass:"align-center justify-center rounded-t-lg",attrs:{"aspect-ratio":17 / 9,"width":"100px","src":require('../../assets/' +
                  (modulo.moduloImgOriginal
                    ? modulo.moduloImgOriginal
                    : 'proximamente.png')),"lazy-src":require("../../assets/client-loader.gif")}},[_c('h1',{staticStyle:{"text-align":"center"}},[_c('v-avatar',{class:_vm.fondoModuloClass(modulo, hover),attrs:{"size":"16","color":"white"}},[_c('v-icon',{attrs:{"size":"16"},on:{"click":function($event){return _vm.activeModulo(modulo)}}},[_vm._v("mdi-play")])],1)],1)])],1),_c('v-scale-transition',[(active)?_c('v-icon',{attrs:{"color":"white","size":"48"},domProps:{"textContent":_vm._s('mdi-close-circle-outline')}}):_vm._e()],1)],1),_c('v-row',[_c('v-col',[_c('p',{staticStyle:{"text-align":"center","color":"#FFF","font-weight":"bold"}},[_vm._v(" Módulo "+_vm._s(modulo.moduloNumber)+" ")])])],1)],1)]}}],null,true)})]}}],null,true)})}),1)}
var staticRenderFns = []

export { render, staticRenderFns }