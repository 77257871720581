<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline" style="word-break:keep-all">
          Deseas eliminar una ruta?
          <div class="pa-1 activo">
            Activo
          </div>
        </v-card-title>
        <v-card-text style="font-size: 1.3rem">
          La ruta a eliminar es <strong> "{{ track.trackName }}" </strong>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="close"> Cancelar </v-btn>
          <v-btn color="green darken-1" text @click="deleteConfirmTrack"> Confirmar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    track: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  methods: {
    ...mapMutations(['SET_ALERT','SET_PROGRESSTRACKS']),
    close () {
      this.$emit('closeDeleted', false)
    },
    async deleteConfirmTrack () {
      let result = (
        await this.$http.post('/progresos/deleteTrack', {
          trackCode: this.track.trackCode
        })
      ).data
      if (result.codigo == '404') {
        this.SET_ALERT({
          message: 'Error al eliminar, inténte de nuevo',
          type: 'error',
          snackbar: true
        })
      } else {
        this.SET_ALERT({
          type: 'success',
          message: 'Ruta eliminada',
          snackbar: true
        })
        this.close()
        this.SET_PROGRESSTRACKS((await this.$http.get('progresos/track')).data)
      }
    }
  },
  data () {
    return {
      //   dialog: false
    }
  }
}
</script>
<style lang="scss" scoped>
.pronto {
  position: absolute;
  top: 0;
  background-color: red;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  font-size: 0.7rem;
  right: 0;
}
.activo {
  position: absolute;
  top: 0;
  background-color: green;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  font-size: 0.7rem;
  right: 0;
}
</style>
